import React from 'react';
import { IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
//import MyComponent from '../components/todo.js';
import { TaskEditor } from '../components/TaskEditor';
import { ListView } from '../components/ListView';
import { SideMenu } from '../components/SideMenu';
import './ListPage.css';

export const ListPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>List</IonTitle>
          <IonMenuButton slot="start" autoHide={true} />
        </IonToolbar>
      </IonHeader>
      <SideMenu />
      <IonContent id="main-menu" fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">List</IonTitle>
          </IonToolbar>
        </IonHeader>
        <TaskEditor existingTask={null} />
        <ListView showCompletedTasksOnly={false} />
      </IonContent>
    </IonPage>
  );
};

export default ListPage;
