import * as CryptoJS from 'crypto-js';

const { AES, SHA256 } = CryptoJS;

/////////////////////////
// ENCRYPTION
/////////////////////////

export const encrypt = (data: string, key: string, tasklenderId: number) => {
    const saltedKey = SHA256(`${key}${tasklenderId}`);
    return AES.encrypt(data, saltedKey.toString()).toString();
}

export const decrypt = (encryptedData: string, key: string, tasklenderId: number): string => {
    // Salt the key with tasklenderId just like we did during encryption
    const saltedKey = SHA256(`${key}${tasklenderId}`);

    // Decrypt the data using the salted key
    const bytes = AES.decrypt(encryptedData, saltedKey.toString());

    // Convert decrypted data back into a string
    const originalData = bytes.toString(CryptoJS.enc.Utf8);

    return originalData;
}

/////////////////////////
// DATABASE FUNCTIONS
/////////////////////////

import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, set, get, push, update, remove } from 'firebase/database';
import { useEffect, useState } from 'react';

// Firebase configuration and initialization
const taskLenderUsername = localStorage.getItem('tasklenderUsername')
const taskLenderPassword = localStorage.getItem('tasklenderPassword')
const config = {
    apiKey: "YOUR_API_KEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "https://tasklender-app.firebaseio.com/",
    projectId: "tasklender-app",
    storageBucket: "YOUR_STORAGE_BUCKET",
    messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
};
const app = initializeApp(config);
export const useFirebaseDatabase = () => {
    const database = getDatabase(app);
    return database;
};
const db = useFirebaseDatabase();
const dbUserTasksRef = (username: string) => ref(db, `${username}/tasks`);

export const addTask = (newTask: TaskModel) => {
    if (taskLenderUsername && taskLenderPassword) {
        newTask.title = encrypt(newTask.title, taskLenderPassword, newTask.tasklenderId);
        push(dbUserTasksRef(taskLenderUsername), newTask);
    }
}

export const editTask = async (taskId: string, editedTask: TaskModel) => {
    const taskRef = ref(db, `${taskLenderUsername}/tasks/${taskId}`);
    const snapshot = await get(taskRef);

    if (snapshot.exists() && taskLenderPassword) {
        editedTask.title = encrypt(editedTask.title, taskLenderPassword, editedTask.tasklenderId);
        update(taskRef, editedTask);
    } else {
        console.log('No such task!');
    }
}

export const useFetchTasks = () => {
    const [tasks, setTasks] = useState<TaskModel[]>([]);
    useEffect(() => {
        taskLenderUsername && onValue(dbUserTasksRef(taskLenderUsername), (snapshot) => {
            const fetchedTasks: TaskModel[] = [];
            snapshot.forEach((snapChild) => {
                const taskData = snapChild.val();
                if (taskLenderPassword && taskData.title) {
                    // Use the decrypt function here for encrypted title
                    taskData.title = decrypt(taskData.title, taskLenderPassword, taskData.tasklenderId);
                }
                fetchedTasks.push({ ...taskData, firebaseId: snapChild.key });
            });
            setTasks(fetchedTasks);
        });
    }, [db]);

    return tasks;
};

export const deleteTask = async (taskId: string) => {
    try {
        const taskRef = ref(db, `${taskLenderUsername}/tasks/${taskId}`);
        await remove(taskRef);
        console.log(`Deleted task with id: ${taskId}`);
    } catch (error: any) {
        console.error(`Failed to delete task: ${error.message}`);
    }
};

export const toggleCompletedStatus = async (taskId: string, isCompleted: boolean) => {
    const taskRef = ref(db, `${taskLenderUsername}/tasks/${taskId}`);
    const snapshot = await get(taskRef);

    if (snapshot.exists()) {
        // Update time to now if setting to complete, or null if setting to incomplete
        set(ref(db, `${taskLenderUsername}/tasks/${taskId}/completedAt`), isCompleted ? null : Date.now());
    } else {
        console.log('No such task!');
    }
};

/////////////////////////
// TASK DATA MODEL
/////////////////////////

export interface TaskModel {
    tasklenderId: number;
    firebaseId?: string | null;
    title: string;
    hardDeadline: number;
    priority: number; // 0-100 %
    eventStartTime: number | null; // null equals task, existing start time equals event
    lanes: string[];
    owner: string | null;
    delayable?: boolean; // for automatic rescheduling
    links?: string[] | null;
    description?: string;
    softDeadline?: number | null;
    modifiedAt?: number[] | null;
    completedAt?: number | null;
    parentTasks?: string[] | null;
    childTasks?: string[] | null;
    comments?: [{
        comment: string;
        createdAt: number;
    }] | null;
    outsourcingOffer?: number | null;
    operativeCosts?: number | null;
    assignees?: string[] | null;
    contexts?: {
        place: string | null;
        coordinates: string | null;
        address: string | null;
        driving: boolean | null;
    } | null;
    estimate?: number | null; // minutes
    timeSpent?: {
        date: number; // beginning of session as Unix timestamp
        duration: number; // minutes
    } | null;
    importSource?: string | null;
    importReferenceLink?: string | null; // link to external task
    attachments?: string[] | null;
    recurring?: boolean | null;
    dependsOn?: string[] | null;
    tags?: string[] | null;
    reportRecord?: string | null; // for automatic reporting
    reportSection?: string | null; // for placing report fragment in the template correctly
}

// TODO: Refactor tasklenderId to be named as createdAt coz firebaseId is the only usable reference to a task
export const getNewTaskDefaultValues = (): TaskModel => {
    return {
        tasklenderId: Date.now(),
        firebaseId: null,
        title: '',
        description: '',
        priority: 50,
        delayable: true,
        hardDeadline: Date.now() + 604800, // this moment + 7 days in seconds
        softDeadline: null,
        eventStartTime: null, // if set, this is an event, not a task
        modifiedAt: [Date.now()],
        completedAt: null,
        parentTasks: null,
        childTasks: null,
        comments: null,
        outsourcingOffer: null,
        operativeCosts: null,
        owner: null,
        assignees: null,
        links: null,
        contexts: {
            place: null,
            coordinates: null,
            address: null,
            driving: false
        },
        estimate: 30, // minutes
        timeSpent: null, // array of session objects or null
        lanes: ['rootLane'],
        importSource: null,
        importReferenceLink: null,
        attachments: null,
        recurring: false,
        dependsOn: null,
        tags: null,
        reportRecord: null,
        reportSection: null
    }
}

/////////////////////////
// DATE CONVERSIONS
/////////////////////////

// TODO: Make sure the conversion is valid
export const isoToUnix = (isoString: string): number => {
    const date = new Date(isoString);
    return Math.floor(date.getTime() / 1000);
}

// TODO: Make sure the conversion is valid
export const unixToIso = (unixTime: number): string => {
    return new Date(unixTime * 1000).toISOString();
}

export const unixTimestampIsValid = (hardDeadline: string): boolean => {
    const re = /^\d+$/;
    return re.test(hardDeadline);
}