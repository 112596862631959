import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './CompletedTasksPage.css';
import { ListView } from '../components/ListView';

const CompletedTasksPage: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Completed tasks</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Calendar</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <ListView showCompletedTasksOnly={true} />
            </IonContent>
        </IonPage>
    );
};

export default CompletedTasksPage;
