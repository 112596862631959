import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonButton, IonLabel, IonMenuButton, IonBackButton, IonButtons } from '@ionic/react';
import './SettingsPage.css';
import { SideMenu } from '../components/SideMenu';
import { Login } from '../components/Login';

const SettingsPage: React.FC = () => {
    const [tasklenderUsername, setTasklenderUsername] = useState(localStorage.getItem('tasklenderUsername') || '');
    const [tasklenderPassword, setTasklenderPassword] = useState(localStorage.getItem('tasklenderPassword') || '');

    useEffect(() => {
        localStorage.setItem('tasklenderUsername', tasklenderUsername);
        localStorage.setItem('tasklenderPassword', tasklenderPassword);
    }, [tasklenderUsername, tasklenderPassword]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Settings</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <SideMenu />
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Settings</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <Login />
            </IonContent>
        </IonPage>
    );
};

export default SettingsPage;
