import React from 'react';
import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonTitle, IonToolbar } from '@ionic/react';
import { checkmarkCircleOutline, settingsOutline, statsChartOutline } from 'ionicons/icons';
import { menuController } from '@ionic/core';

export const SideMenu: React.FC = () => {

    const closeMenu = async () => {
        // await the menu to close, then navigate
        await menuController.close();
    };

    return (
        <IonMenu side="start" contentId="main-menu">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Start Menu</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem routerLink="/settings" onClick={closeMenu}>
                        <IonIcon slot="start" icon={settingsOutline} />
                        <IonLabel>Settings</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/completed" onClick={closeMenu}>
                        <IonIcon slot="start" icon={checkmarkCircleOutline} />
                        <IonLabel>Completed tasks</IonLabel>
                    </IonItem>
                    <IonItem routerLink="/stats" onClick={closeMenu}>
                        <IonIcon slot="start" icon={statsChartOutline} />
                        <IonLabel>Stats & Projections</IonLabel>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};
