import React, { useRef, useState } from 'react';
import { IonList, IonItem, IonFab, IonFabButton, IonIcon, IonItemSliding, IonItemOptions, IonItemOption, IonNote, IonCheckbox } from '@ionic/react';
import { add } from 'ionicons/icons';
import { EditTaskModal } from './EditTaskModal';
import { useFetchTasks, toggleCompletedStatus, TaskModel, deleteTask, decrypt } from './utils';
import { format } from 'date-fns';


export const ListView = ({ showCompletedTasksOnly }: { showCompletedTasksOnly: boolean }) => {
    let tasks = useFetchTasks();
    //testEncryption();

    const tasklenderPassword = localStorage.getItem('tasklenderPassword') || '';
    console.log('tasklenderPassword', tasklenderPassword)


    const [taskEditorModalOpen, setTaskEditorModalOpen] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);

    const slidingItemRef = useRef<HTMLIonItemSlidingElement>(null);

    const handleSwipe = async (task: TaskModel) => {
        console.log('hello from handleSwipe');
        await toggleCompletedStatus(task.firebaseId || '', !!task.completedAt);
        slidingItemRef.current?.closeOpened(); // Close slider
    };

    const handleCheckboxClick = async (task: TaskModel, e: CustomEvent) => {
        e.stopPropagation();
        await toggleCompletedStatus(task.firebaseId || '', !e.detail.checked);
    };

    const handleDelete = async (firebaseId: string) => {
        console.log(`Deleting task with id: ${firebaseId}`);
        await deleteTask(firebaseId);

        // Close slider
        slidingItemRef.current?.closeOpened();
    };

    if (tasks && !showCompletedTasksOnly) {
        tasks = tasks.filter(task => !task.completedAt).sort((a, b) => a.hardDeadline - b.hardDeadline);
    } else if (tasks) {
        tasks = tasks.filter(task => !!task.completedAt).sort((a, b) => a.hardDeadline - b.hardDeadline);
    }

    return (
        <>
            <IonList>
                {tasks && tasks.map((task: TaskModel, index: number) => (
                    <IonItemSliding key={index} ref={slidingItemRef}>
                        <IonItem>
                            <div style={{ display: 'flex', width: '100%', alignItems: 'center', overflow: 'hidden' }}>
                                <IonCheckbox
                                    justify="start"
                                    slot="start"
                                    checked={!!task.completedAt} // convert to boolean here
                                    style={{ '--checkbox-background': (new Date(task.hardDeadline).getTime() - new Date().getTime()) < 86400000 ? '#f00' : '#111' }}
                                    onIonChange={e => {
                                        e.stopPropagation();
                                        handleCheckboxClick(task, e);
                                    }}
                                />
                                <div
                                    className="truncate"
                                    style={{ marginLeft: '1em', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', flex: '1' }}
                                    onClick={() => {
                                        setSelectedTask(task);
                                        if (task) {
                                            setTaskEditorModalOpen(true);
                                        }
                                    }}>
                                    {task.title}
                                </div>

                                <IonNote>
                                    ({format(task.hardDeadline, 'yyyy-MM-dd')})
                                </IonNote>
                            </div>
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption color="danger" onClick={() => handleSwipe(task)}>
                                {showCompletedTasksOnly ? "Mark undone" : "Mark done"}
                            </IonItemOption>
                            <IonItemOption color="dark" onClick={() => handleDelete(task.firebaseId ? task.firebaseId : '')}>
                                Delete
                            </IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                ))}
                <EditTaskModal
                    onDismiss={() => { setTaskEditorModalOpen(false); setSelectedTask(null) }}
                    task={selectedTask}
                    isOpen={taskEditorModalOpen}
                />
            </IonList>

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton onClick={() => {
                    setSelectedTask(null);
                    setTaskEditorModalOpen(true); 
                }}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
        </>
    );
};
