import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { timerOutline, listOutline, calendarNumberOutline } from 'ionicons/icons';
import ListPage from './pages/ListPage';
import AgendaPage from './pages/AgendaPage';
import CalendarPage from './pages/CalendarPage';
import CompletedTasksPage from './pages/CompletedTasksPage';
import SettingsPage from './pages/SettingsPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (

  <IonApp>

    <IonReactRouter>

      <IonTabs>

        <IonRouterOutlet id="main">
          <Route exact path="/list">
            <ListPage />
          </Route>
          <Route exact path="/agenda">
            <AgendaPage />
          </Route>
          <Route path="/calendar">
            <CalendarPage />
          </Route>
          <Route path="/settings">
            <SettingsPage />
          </Route>
          <Route path="/completed">
            <CompletedTasksPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/list" />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/list">
            <IonIcon aria-hidden="true" icon={listOutline} />
            <IonLabel>List</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/agenda">
            <IonIcon aria-hidden="true" icon={timerOutline} />
            <IonLabel>Agenda</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/calendar">
            <IonIcon aria-hidden="true" icon={calendarNumberOutline} />
            <IonLabel>Calendar</IonLabel>
          </IonTabButton>
        </IonTabBar>

      </IonTabs>

    </IonReactRouter>

  </IonApp>

);

export default App;
