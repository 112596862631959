import React, { useRef, useState } from 'react';
import {
    IonButton,
    IonInput,
    IonLabel,
    IonItem,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonRange,
    IonToast,
    IonTextarea,
} from '@ionic/react';
import 'firebase/database';
import { TaskModel, addTask, editTask, getNewTaskDefaultValues } from './utils'

interface TaskEditorProps {
    existingTask: TaskModel | null;
    callbackOnSave?: () => void;
}

export const TaskEditor = ({ existingTask, callbackOnSave }: TaskEditorProps) => {

    // CONSTANTS

    const dayInMs = 60 * 60 * 24 * 1000

    // STATES

    const [editableTaskTitle, setEditableTaskTitle] = useState(existingTask ? existingTask.title : '')
    const [editableTaskHardDeadline, setEditableTaskHardDeadline] = useState(existingTask ? existingTask.hardDeadline : Date.now() + (dayInMs * 7))
    const [editableTaskPriority, setEditableTaskPriority] = useState(existingTask ? existingTask.priority : 50)
    const [editableTaskDescription, setEditableTaskDescription] = useState(existingTask ? existingTask.description : '');

    // UI STATES
    const [showToast, setShowToast] = useState(false); // for controlling toast visibility

    // FUNCTIONS

    const updateTextBoxValue = (text: string) => {
        setEditableTaskTitle(text)
    }

    const descriptionRef = useRef<HTMLIonTextareaElement>(null);

    const keyPress = (e: React.KeyboardEvent<any>) => {
        if (e.key === 'Tab') {
            e.preventDefault(); // stop default Tab behavior
            // move focus to Description textarea
            descriptionRef.current?.setFocus();
        }
        if (e.key === 'Enter') {
            onAddTaskClick();
        }
    }

    // TODO: Refactor callbackOnSave to something else, like callbackOnClose.
    // Or get rid of callback hell using atom state.
    const handleModalClose = () => {
        if (callbackOnSave) callbackOnSave();
    }

    const onAddTaskClick = () => {
        if (!editableTaskTitle || editableTaskTitle.trim() === '') {
            setShowToast(true);
            return;
        }
        const editableTask = getNewTaskDefaultValues(); // gets default values
        editableTask.firebaseId = existingTask ? existingTask.firebaseId : null
        editableTask.title = editableTaskTitle && editableTaskTitle !== '' ? editableTaskTitle : ''
        editableTask.description = editableTaskDescription && editableTaskDescription !== '' ? editableTaskDescription : ''
        editableTask.hardDeadline = editableTaskHardDeadline ? editableTaskHardDeadline : Date.now()
        editableTask.priority = editableTaskPriority ? editableTaskPriority : 50

        if (!existingTask) {
            addTask(editableTask);
        }
        else {
            if (editableTask && typeof editableTask.firebaseId === 'string') {
                editTask(editableTask.firebaseId, editableTask);
            }
            else {
                console.log("Something went wrong with the task update.")
            }
        }
        setEditableTaskTitle('')
        setEditableTaskHardDeadline(Date.now() + (dayInMs * 7))
        setEditableTaskPriority(50)
        setEditableTaskDescription('')
        // TODO: Consider having TaskEditorModal state in an atom
        if (callbackOnSave) {
            callbackOnSave();
        }
    }

    const updateHardDeadline = (dateStringISO: string) => {
        setEditableTaskHardDeadline(Date.parse(dateStringISO))
    }

    const updateTaskPriority = (priority: number) => {
        setEditableTaskPriority(priority)
    }

    const updateDescriptionValue = (text: string) => {
        setEditableTaskDescription(text);
    }

    // TODO: Refactor to use ShadCN React Hook Form: https://ui.shadcn.com/docs/components/form
    // TODO: Refactor the IonLabel from legacy to modern markup based on Inner Circle code

    return (
        <IonCard color="light">
            <IonCardHeader>
                <IonItem color="light">
                    <IonLabel position="floating">Let this be done</IonLabel>
                    <IonInput
                        type="text"
                        value={editableTaskTitle}
                        onKeyDown={keyPress}
                        onIonChange={(textBoxElement) =>
                            textBoxElement.target.value &&
                            updateTextBoxValue(textBoxElement.target.value.toString())}
                    ></IonInput>
                </IonItem>
            </IonCardHeader>
            <IonCardContent>
                <IonItem color="light">
                    <IonLabel>Hard deadline</IonLabel>
                    <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
                    <IonModal keepContentsMounted={true}>
                        <IonDatetime
                            id="datetime"
                            max="2030"
                            value={new Date(editableTaskHardDeadline).toISOString()}
                            prefer-wheel
                            onIonBlur={(hardDeadlinePicker) => {
                                if (typeof hardDeadlinePicker.target.value === 'string') {
                                    updateHardDeadline(hardDeadlinePicker.target.value);
                                }
                            }}
                        ></IonDatetime>
                    </IonModal>
                </IonItem>
                <IonItem color="light">
                    <IonLabel>Priority</IonLabel>
                    <IonRange min={0} max={100} value={editableTaskPriority}
                        onIonChange={(taskPriorityRange) => {
                            if (typeof taskPriorityRange.target.value === 'number') {
                                updateTaskPriority(taskPriorityRange.target.value);
                            }
                        }}>
                        <IonLabel slot="end">{editableTaskPriority} %</IonLabel>
                    </IonRange>
                </IonItem>
                <IonItem color="light">
                    <IonLabel position="floating">Description</IonLabel>
                    <IonTextarea
                        value={editableTaskDescription}
                        ref={descriptionRef}   // assign the ref here
                        onIonChange={(e) =>
                            e.target.value && updateDescriptionValue(e.target.value.toString())}
                    ></IonTextarea>
                </IonItem>

                <IonButton onClick={() => onAddTaskClick()} style={{ marginTop: '1.5em' }}>{existingTask ? 'Save' : 'Create'}</IonButton>
                <IonButton onClick={handleModalClose} style={{ marginTop: '1.5em', marginLeft: '1em' }}>Cancel</IonButton>
            </IonCardContent>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message="Something went wrong with the task update."
                duration={2000}
            />
        </IonCard>
    )
}
