import React from 'react';
import { IonModal } from '@ionic/react';
import { TaskModel } from './utils';
import { TaskEditor } from './TaskEditor';
import './EditTaskModal.css';

interface Props {
    task: TaskModel | null;
    isOpen: boolean;
    onDismiss: () => void;
}

export const EditTaskModal: React.FC<Props> = ({ task, isOpen, onDismiss }) => {

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
            <TaskEditor existingTask={task} callbackOnSave={onDismiss} />
        </IonModal>
    );

};
