import React from 'react';
import { IonButton, IonCard, IonInput, IonItem, IonLabel } from '@ionic/react';
import { useForm } from "react-hook-form";

export const Login: React.FC = () => {
    const { register, handleSubmit } = useForm();

    return (
        <form onSubmit={handleSubmit((formValues) => {
            localStorage.setItem('tasklenderUsername', formValues.tasklenderUsername);
            localStorage.setItem('tasklenderPassword', formValues.tasklenderPassword);
            console.log(JSON.stringify(formValues))
        })}>
            <IonCard color="light">
                <IonItem color="light">
                    <IonLabel position="floating">
                        Username:
                    </IonLabel>
                    <IonInput {...register("tasklenderUsername")} value={localStorage.getItem('tasklenderUsername') || ''} placeholder="Enter Username" />
                </IonItem>
                <IonItem color="light">
                    <IonLabel position="floating">
                        Password:
                    </IonLabel>
                    <IonInput {...register("tasklenderPassword")} type="password" value={localStorage.getItem('tasklenderPassword') || ''} placeholder="Enter Password" />
                </IonItem>
                <IonItem color="light">
                    <IonButton type="submit">Save</IonButton>
                </IonItem>
            </IonCard>
        </form >
    );
};
